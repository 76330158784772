import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BancontactQRCode from '../../../components/Event/PaymentQRCode';
import { api, catchErrorAPI } from '../../../api/apiConfig';
import { useParams } from 'react-router-dom';

const CompConfirmationPage = () => {
    const { idConfirmation } = useParams();
    const [loading, setLoading] = useState(true);
    const [registration, setRegistration] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        document.title = "Confirmation | UAC";
    
        const fetchData = async () => {
          try{
            const response = await api.get(`/registration/${idConfirmation}`);
            setRegistration(response.data);

            setLoading(false);
          }catch(err){
            console.log(err);
            catchErrorAPI(err);
          }
        }
        fetchData();
      }, []);
  
  
  
    if (loading) {
      return (
        <Container className="bloc">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
          </Spinner>
        </Container>
      )
    }
  

    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Confirmation</span></h1>
                </Col>
            </Row>
            <Row className='mt-4 bloc'>
                <Col className='mx-2'>
                    <h3 className="titleh1"><span className="jaune">Merci pour votre inscription</span></h3> <br/>
                    <p>
                        Votre inscription a bien été prise en compte ! 
                    </p>
                        
                    
                </Col>
            </Row>
            <Row>
                
            </Row>
        </Container>
    )
}

export default CompConfirmationPage