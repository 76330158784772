import { Container, Row, Card, Col, Spinner, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react";
import { api, catchErrorAPI } from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";

function InformationsHomePage() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [informations, setInformations] = useState([]);
    const [selectedInfo, setSelectedInfo] = useState("");

    const handleInfoClick = (information) => {
        setSelectedInfo(information);
    };


    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const response = await api.get('/info');
                setInformations(response.data);
                setLoading(false);
            } catch (err) {
                catchErrorAPI(err);
            }
        }
        fetchInfo();
    }, [])


    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container className="bloc">
            <Row className="text-center">
                <h1 className="titleHomePage"><span className="jaune"><FontAwesomeIcon icon={faBullhorn} /> Informations</span></h1>
            </Row>
            <Row className='py-2' xs={1} md={1} lg={1}>
                {
                    informations.map((information => {
                        return (
                            <Col className='py-2' key={information._id}>
                                <Card
                                    className={`text-center cardHomePage ${
                                        selectedInfo === information ? 'selectedCard' : ''
                                    }`}
                                >
                                    {!(information._id === selectedInfo._id) &&
                                    <Card.Body className="cardText">{information.label.substring(0, 200)}{information.label.length > 100 ? "..." : ""} <u onClick={() => handleInfoClick(information)}>Voir plus</u></Card.Body>
                                    }

                                    {(information._id === selectedInfo._id) &&
                                        <Card.Body className="cardText">{information.label} </Card.Body>
                                    }
                                </Card>
                            </Col>
                        )
                    }))

                }
                 <Col className='py-2'>
                    <Button className="buttonJaune p-3" onClick={() => {navigate(`/MeetingCloture`)}}>Meeting de Cloture 05/10/24 <FontAwesomeIcon icon={faArrowRight} /></Button>
                </Col>
            </Row>
        </Container>
    )
}

export default InformationsHomePage;