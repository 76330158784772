import React from 'react';
import { useState, useEffect } from "react";
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { api, catchErrorAPI } from '../../../api/apiConfig';

const CompCancelPage = () => {
    let navigate = useNavigate();

    const { idEvent } = useParams();
    const [message, setMessage] = useState("");
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {

            const responseEvent = await api.get(`/event/${idEvent}`);
            setEvent(responseEvent.data);

            setLoading(false);
        } catch (err) {
            catchErrorAPI(err);
        }
    }


    const [initialValues, setInitialValues] = useState({
        emailAdress: '',
        dossard: '',
    });

    const validationSchema = Yup.object().shape({
        emailAdress: Yup.string()
            .required('Un email est requis')
            .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                'Veuillez fournir une adresse email valide'
            ),
        dossard: Yup.string().required('Un dossard est requis'),
    });

    const cancelInscription = async (values) => {
        
        try {

            let data = await api.put(`/cancelRegistration`, {
                idEvent : event._id,
                emailAdress: values.emailAdress,
                dossard: values.dossard,
            });

            setInitialValues({
                emailAdress: '',
                dossard: 0,
            });

            setMessage({ error: false, msg: data.data })

        

        } catch (error) {
            setMessage({ error: true, msg: error.response.data });
        }


    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }



    return (
        <Container>
            <Row className='py-3 d-flex align-items-center justify-content-between' xs={3} md={3} lg={3}>
                <Col xs={1} md={2} lg={2} className="text-left">
                    <span className="returnButton" onClick={() => { navigate(-1) }}>
                        <FontAwesomeIcon id="faChevronLeft" icon={faArrowLeft} />
                        <span className="d-none d-md-inline"> Retour</span>
                    </span>
                </Col>
                <Col xs={10} md={10} lg={10} className="text-center"><h1 className="titleh1"><span className="jauneTitre">Inscription {event.label}</span></h1></Col>
                <Col xs={1} md={2} lg={2}></Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Annulation</span></h3><br />   
                    <p>
                        Si vous souhaitez <u>annuler votre inscription</u>, utilisez ce formulaire.
                    </p>
                    <p>Si vous souhaitez effectuer <u>des modifications à une inscription existante</u>, veuillez d'abord annuler l'inscription ici, puis procédez à une nouvelle inscription avec les informations mises à jour.</p>


                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            cancelInscription(values);

                        }}
                    >
                        {({ values, errors, touched }) => (
                            <Form>
                                <label htmlFor="emailAdress">Email</label> <br />
                                <Field type="email" id="emailAdress" name="emailAdress" className="fieldTitleNews" /><br />

                                <label htmlFor="dossard">Dossard</label> <br />
                                <Field type="number" id="dossard" name="dossard" className="fieldTitleNews" /><br />

                                <div>
                                    <p><ErrorMessage name="emailAdress" /></p>
                                    <p><ErrorMessage name="dossard" /></p>
                                </div>

                                {message?.msg && (
                                    <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                                        {message?.msg}
                                    </Alert>)
                                }

                                <Button className="mt-3 buttonJaune" size="lg" type="submit" >Annuler l'inscription <FontAwesomeIcon icon={faArrowRight} /></Button>
                            </Form>)}
                    </Formik>





                </Col>
            </Row>
        </Container>
    )
}

export default CompCancelPage