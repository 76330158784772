import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

const CompetitionPage = () => {

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Meeting de cloture | UAC";
    }, []);


    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Meeting de cloture - 05 octobre 2024</span></h1>
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Présentation</span></h3><br />
                    <p>
                        Rejoignez-nous le 5 octobre 2024 au meeting de clôture de l'UAC, un meeting ouvert dédié aux épreuves de lancers et de sauts. Nous espérons vous voir nombreux !
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Inscriptions</span></h3><br />
                    <p>
                        <ul>
                            <li><b>Préinscriptions obligatoires avant le 2 octobre 2024 23h59</b></li>
                            <li>3€ par épreuve (Gratuit pour les BPM de l'UAC)</li>
                            <li>Confirmation et paiement sur place <b>45 min avant l'épreuve</b></li>
                            <li>Dossard d'un jour disponible sur place pour les BPM sans affiliation</li>
                        </ul>
                    </p>

                    <Button className="mt-3 buttonJauneExterne" size="lg" onClick={() => { navigate(`/MeetingCloture/annulation/66da1d0c63ffe28f09946a7d`) }} >Modifier / Annuler <FontAwesomeIcon icon={faArrowRight} /></Button> {` `}

                    <Button className="mt-3 buttonJaune" size="lg" onClick={() => { navigate("/MeetingCloture/inscription") }} >Inscription <FontAwesomeIcon icon={faArrowRight} /></Button>
                </Col>
            </Row>
            <Row>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Informations pratiques</span></h3><br />
                    <h4 className='titleh4'><span className="jaune">Horaire</span></h4><br />

                    <Table striped bordered hover>
                        <thead>
                            <tr>

                                <th>Heure</th>
                                <th>Épreuve</th>
                                <th>Catégorie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="3">10h00</td>
                                <td>Hauteur</td>
                                <td>Pup F</td>
                            </tr>
                            <tr>

                                <td>Longueur</td>
                                <td>Pup G</td>
                            </tr>
                            <tr>

                                <td>Poids</td>
                                <td>Ben F</td>
                            </tr>
                            <tr>
                                <td rowspan="2">10h50</td>
                                <td>Poids</td>
                                <td>Min F</td>
                            </tr>
                            <tr>

                                <td>Longueur</td>
                                <td>Min G</td>
                            </tr>
                            <tr>
                                <td>11h05</td>
                                <td>Balle de Hockey</td>
                                <td>Ben G</td>
                            </tr>
                            <tr>
                                <td rowspan="2">11h40</td>
                                <td>Balle de Hockey</td>
                                <td>Pup G</td>
                            </tr>
                            <tr>

                                <td>Longueur</td>
                                <td>Ben F</td>
                            </tr>
                            <tr>
                                <td>11h50</td>
                                <td>Poids</td>
                                <td>Pup F</td>
                            </tr>
                            <tr>
                                <td rowspan="2">12h30</td>
                                <td>Hauteur</td>
                                <td>Min F</td>
                            </tr>
                            <tr>

                                <td>Disque</td>
                                <td>Min G</td>
                            </tr>
                            <tr>
                                <td>12h45</td>
                                <td>Longueur</td>
                                <td>Ben G</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                            </tr>
                            <tr>
                                <td rowspan="2">14h00</td>
                                <td>Disque</td>
                                <td>TC Hommes</td>
                            </tr>
                            <tr>

                                <td>Poids</td>
                                <td>TC Dames</td>
                            </tr>
                            <tr>
                                <td>15h00</td>
                                <td>Hauteur</td>
                                <td>TC Dames</td>
                            </tr>
                            <tr>
                                <td rowspan="3">15h30</td>
                                <td>Longueur</td>
                                <td>TC Hommes</td>
                            </tr>
                            <tr>

                                <td>Javelot</td>
                                <td>TC Hommes</td>
                            </tr>
                            <tr>

                                <td>Disque</td>
                                <td>TC Dames</td>
                            </tr>
                            <tr>
                                <td>16h30</td>
                                <td>Hauteur</td>
                                <td>TC Hommes</td>
                            </tr>
                            <tr>
                                <td>16h45</td>
                                <td>Longueur</td>
                                <td>TC Dames</td>
                            </tr>
                            <tr>
                                <td rowspan="2">17h00</td>
                                <td>Poids</td>
                                <td>TC Hommes</td>
                            </tr>
                            <tr>

                                <td>Javelot</td>
                                <td>TC Dames</td>
                            </tr>
                        </tbody>
                    </Table>

                    Note : Si plus de 25 athlètes par épreuve, 2 essais maximum seront autorisés

                    <h4 className='titleh4'><span className="jaune">Lieu</span></h4>
                    <p>
                        Stade du Taillis-Pré <br />
                        Rue de l'Église à 6200 Châtelineau
                    </p>
                </Col>
            </Row>

        </Container>
    )
}

export default CompetitionPage