import React from 'react';
import { useState, useEffect } from "react";
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { api, catchErrorAPI } from '../../../api/apiConfig';

const CompRegistrationPage = () => {
    let navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    const categories = [
        { value: 'h_ben', label: 'Benjamin (Homme)' },
        { value: 'f_ben', label: 'Benjamine (Femme)' },
        { value: 'h_pup', label: 'Pupille (Homme)' },
        { value: 'f_pup', label: 'Pupille (Femme)' },
        { value: 'h_min', label: 'Minime (Homme)' },
        { value: 'f_min', label: 'Minime (Femme)' },
        { value: 'h_cad', label: 'Cadet (Homme)' },
        { value: 'f_cad', label: 'Cadette (Femme)' },
        { value: 'h_sco', label: 'Scolaire (Homme)' },
        { value: 'f_sco', label: 'Scolaire (Femme)' },
        { value: 'h_jun', label: 'Junior (Homme)' },
        { value: 'f_jun', label: 'Junior (Femme)' },
        { value: 'h_sen', label: 'Senior (Homme)' },
        { value: 'f_sen', label: 'Senior (Femme)' },
        { value: 'h_mas', label: 'Master (Homme)' },
        { value: 'f_mas', label: 'Master (Femme)' },

    ]
    const eventsByCategory = {
        'h_ben': ['Balle de hockey', 'Longueur'],
        'f_ben': ['Poids', 'Longueur'],
        'h_pup': ['Balle de hockey', 'Longueur'],
        'f_pup': ['Hauteur', 'Poids'],
        'h_min': ['Disque', 'Longueur'],
        'f_min': ['Hauteur', 'Poids'],
        'h_cad': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'f_cad': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'h_sco': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'f_sco': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'h_jun': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'f_jun': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'h_sen': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'f_sen': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'h_mas': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
        'f_mas': ['Disque', 'Hauteur', 'Javelot', 'Longueur', 'Poids'],
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await api.get(`/event/66da1d0c63ffe28f09946a7d`);
                setEvent(response.data);

                document.title = `${response.data.label} | UAC`;


                setLoading(false);
            } catch (err) {
                catchErrorAPI(err);
            }
        }
        fetchData();
    }, []);


    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        emailAdress: '',
        emailAdressConfirmation: '',
        rgpdConsent: false,
        dossard: '',
        category: '',
        club: '',
        selectedEpreuves: [], 
        performance: {}
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Un prénom est requis'),
        lastName: Yup.string().required('Un nom est requis'),
        emailAdress: Yup.string()
            .required('Un email est requis')
            .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                'Veuillez fournir une adresse email valide'
            ),
        emailAdressConfirmation: Yup.string()
            .required('La confirmation d\'email est requise')
            .oneOf([Yup.ref('emailAdress')], 'Les adresses email ne correspondent pas'),
        rgpdConsent: Yup.boolean().oneOf([true], 'Vous devez donner votre consentement pour utiliser vos données'),
        dossard: Yup.string().required('Un dossart est requis'),
        category: Yup.string().required('Une catégorie est requise'),
        club: Yup.string().required('Un club est requis'),
        selectedEpreuves: Yup.array()
            .min(1, 'Sélectionnez au moins une épreuve.'),
    });

    const addInscription = async (values) => {

        const disciplineRegistrations = values.selectedEpreuves.map(selectedEpreuve => {
            let perf = 0
            if(values.performance[selectedEpreuve]){
                perf = values.performance[selectedEpreuve]
            }

            return { discipline: selectedEpreuve, performance: perf }
        })

        try {

            let data = await api.post(`/registration/${event._id}`, {
                firstName: values.firstName,
                lastName: values.lastName,
                emailAdress: values.emailAdress,
                rgpdConsent: values.rgpdConsent,
                inscriptionDate: new Date(Date.now()),
                status: "registered",
                dossard: values.dossard,
                category: values.category,
                club: values.club,
                sexe: values.category.charAt(0),
                disciplineRegistrations: disciplineRegistrations
            });

            navigate(`/MeetingCloture/confirmation/${data.data._id}`);

        } catch (error) {
            setMessage({ error: true, msg: error.response.data });
        }


    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Row className='py-3 d-flex align-items-center justify-content-between' xs={3} md={3} lg={3}>
                <Col xs={1} md={2} lg={2} className="text-left">
                    <span className="returnButton" onClick={() => { navigate(-1) }}>
                        <FontAwesomeIcon id="faChevronLeft" icon={faArrowLeft} />
                        <span className="d-none d-md-inline"> Retour</span>
                    </span>
                </Col>
                <Col xs={10} md={10} lg={10} className="text-center"><h1 className="titleh1"><span className="jauneTitre">Inscription {event.label}</span></h1></Col>
                <Col xs={1} md={2} lg={2}></Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Inscription</span></h3><br />


                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            addInscription(values);

                        }}
                    >
                        {({ values, errors, touched }) => (
                            <Form>
                                <label htmlFor="firstName">Prénom</label> <br />
                                <Field id="firstName" name="firstName" className="fieldTitleNews" /><br />

                                <label htmlFor="lastName">Nom</label> <br />
                                <Field id="lastName" name="lastName" className="fieldTitleNews" /><br />

                                <label htmlFor="emailAdress">Email</label> <br />
                                <Field type="email" id="emailAdress" name="emailAdress" className="fieldTitleNews" /><br />

                                <label htmlFor="emailAdressConfirmation">Confirmer l'adresse mail</label> <br />
                                <Field type="email" id="emailAdressConfirmation" name="emailAdressConfirmation" className="fieldTitleNews" /><br />

                                <label htmlFor="dossard">Dossard</label> <br />
                                <Field id="dossard" name="dossard" type="number" className="fieldTitleNews" /><br />

                                <label htmlFor="club">Club</label> <br />
                                <Field id="club" name="club" className="fieldTitleNews" /><br />

                                <label htmlFor="category">Catégorie</label><br />
                                <Field as="select" name="category">
                                    <option value="">Sélectionnez une catégorie</option>
                                    {categories.map(category => (
                                        <option key={category.value} value={category.value}>
                                            {category.label}
                                        </option>
                                    ))}
                                </Field> <br /><br />

                                <FieldArray name="selectedEpreuves">
                                    {({ push, remove }) => (
                                        <fieldset>
                                            {values.category &&
                                                eventsByCategory[values.category].map(event => (
                                                    <div key={event}>
                                                        <label>
                                                            <Field type="checkbox" name="selectedEpreuves" value={event} />
                                                            {` `}{event}
                                                        </label>
                                                        {values.selectedEpreuves.includes(event) && (
                                                            <div>
                                                                <label>
                                                                    Performance de référence :{` `}
                                                                </label>
                                                                <Field type="number" step="0.01"  name={`performance.${event}`} placeholder="Exemple : 4.20"/> 
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                        </fieldset>
                                    )}
                                </FieldArray> <br /><br />



                                <label htmlFor="rgpdConsent" id="rgpdConsentLabel" className='rgpdConsentLabel'>
                                    <Field type="checkbox" name="rgpdConsent" id="rgpdConsent" className="rgpdConsent" /> {` `}
                                    J'accepte que mes données soient utilisées dans le cadre unique de l'inscription à cet évenement
                                </label>

                                <div>
                                    <p><ErrorMessage name="firstName" /></p>
                                    <p><ErrorMessage name="lastName" /></p>
                                    <p><ErrorMessage name="emailAdress" /></p>
                                    <p><ErrorMessage name="emailAdressConfirmation" /></p>
                                    <p><ErrorMessage name="rgpdConsent" /></p>
                                    <p><ErrorMessage name="dossard" /></p>
                                    <p><ErrorMessage name="category" /></p>
                                    <p><ErrorMessage name="club" /></p>
                                    <p><ErrorMessage name="selectedEpreuves" /></p>
                                </div>

                                {message?.msg && (
                                    <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                                        {message?.msg}
                                    </Alert>)
                                }

                                <Button className="mt-3 buttonJaune" size="lg" type="submit" >Valider l'inscription <FontAwesomeIcon icon={faArrowRight} /></Button>
                            </Form>)}
                    </Formik>





                </Col>
            </Row>
        </Container>
    )
}

export default CompRegistrationPage